var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.breakdown
    ? _c(
        "div",
        { staticClass: "score" },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.noninteractive",
                  modifiers: { hover: true, noninteractive: true }
                }
              ],
              class:
                "score--num score--num__" +
                (_vm.breakdown.first ? _vm.breakdown.first.role : ""),
              attrs: {
                title:
                  _vm.breakdown.first != null
                    ? "First half"
                    : "Missing information"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.breakdown.first != null
                      ? _vm.breakdown.first.score
                      : "NA"
                  )
              )
            ]
          ),
          _vm._v(" / "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.noninteractive",
                  modifiers: { hover: true, noninteractive: true }
                }
              ],
              class:
                "score--num score--num__" +
                (_vm.breakdown.second ? _vm.breakdown.second.role : ""),
              attrs: {
                title:
                  _vm.breakdown.second != null
                    ? "Second half"
                    : "Missing information"
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.breakdown.second != null
                      ? _vm.breakdown.second.score
                      : "NA"
                  ) +
                  " "
              )
            ]
          ),
          _vm.breakdown.overtime != null
            ? [
                _vm._v(" / "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.noninteractive",
                        modifiers: { hover: true, noninteractive: true }
                      }
                    ],
                    class: "score--num score--num__overtime",
                    attrs: {
                      title:
                        _vm.breakdown.overtime != null
                          ? "Overtime"
                          : "Missing information"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.breakdown.overtime.score) + " ")]
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }